import React from 'react';
type PopUpProps = {
  size: string;
};
export default function DemoPopUp({ size }: PopUpProps) {
  return (
    <div className="mt-8">
      <iframe
        width={size}
        height="315"
        src="https://www.youtube.com/embed/kcfs1-ryKWE?si=jfW1lXtZTpLhnu3h&controls=1&autoplay=1&mute=1"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );
}
