// import Mapbox from 'components/base/MapBox';
import { Col, Row } from 'react-bootstrap';
import HelpCenter from '../default/address/HelpCenter';
import QueryForm from '../default/address/QueryForm';

const AddressSection = () => {
  return (
    <section className="pb-10 pb-xl-14 mt-10">
      <div className="container-small px-lg-7 px-xxl-3">
        <div className="text-center mb-7">
          <h5 className="text-info mb-3">Contact</h5>
          <h2 className="mb-2">Reach out to us</h2>
        </div>
        <div className="mb-15">
          {/* <Mapbox
            className="rounded-4"
            style={{ height: 380 }}
            options={{
              center: [13.104312, 77.609687],
              zoom: 14,
              scrollZoom: false
            }}
          /> */}
          <iframe
            width="100%"
            height="600"
            scrolling="no"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Canopy%20Crest,%20Kogilu%20Main%20Rd,%20Maruthi%20Nagar,%20Kogilu,%20Bengaluru,%20Karnataka%20560064+(MultiD)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          >
            <a href="https://www.gps.ie/">gps tracker sport</a>
          </iframe>
        </div>
        <Row className="g-5 g-lg-5">
          <Col
            xs={12}
            md={6}
            className="mb-5 mb-md-0 text-center text-md-start"
          >
            <HelpCenter />
          </Col>
          <Col xs={12} md={6} className="text-center text-md-start">
            <QueryForm />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default AddressSection;
