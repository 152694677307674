import icon1 from 'assets/img/icons/lightning-speed-r.png';
import darkIcon1 from 'assets/img/icons/lightning-speed-dark.png';
import icon2 from 'assets/img/icons/best-statistics-r.png';
import darkIcon2 from 'assets/img/icons/best-statistics-dark.png';
import icon3 from 'assets/img/icons/all-night-r.png';
import darkIcon3 from 'assets/img/icons/all-night-dark.png';
import icon4 from 'assets/img/icons/editable-features-r.png';
import darkIcon4 from 'assets/img/icons/editable-features-dark.png';
import blog5 from 'assets/img/blog/multic.jpg';
import blog6 from 'assets/img/blog/plane.jpg';
import blog7 from 'assets/img/blog/vtol.jpg';
import pranav from 'assets/img/team/pranav.jpg';
import amartya from 'assets/img/team/amartya.jpg';
import yash from 'assets/img/team/yash.png';
import gana from 'assets/img/team/gana.jpg';
// import team73 from 'assets/img/team/73.webp';
// import team74 from 'assets/img/team/74.webp';
// import team75 from 'assets/img/team/75.webp';
// import team76 from 'assets/img/team/76.webp';
// import team20 from 'assets/img/team/20.webp';

import money from 'assets/img/spot-illustrations/money.png';
import pilot from 'assets/img/spot-illustrations/pilot_single.png';
import drone from 'assets/img/spot-illustrations/signald.png';

export interface Feature {
  id: number;
  title: string;
  description: string;
  lightImg: string;
  darkImg: string;
}

export interface Stat {
  id: number;
  title: string;
  value: number;
  prefix?: string;
  suffix?: string;
}

export interface Blog {
  id: number;
  image: string;
  views: number;
  like: number;
  comments: number;
  category: string;
  title: string;
}

export interface TeamMember {
  id: number;
  image: string;
  name: string;
  designation: string;
}

export const features: Feature[] = [
  {
    id: 1,
    title: 'Recieve signals anywhere',
    description:
      'MultiD allows you to receive every signal instantly and fruitfully. No need for long waits.',
    lightImg: drone,
    darkImg: drone
  },
  {
    id: 2,
    title: 'Make every flight a joyride',
    description:
      'MultiD helps you maintain stability and control to make the flight effortless.',
    lightImg: pilot,
    darkImg: pilot
  },
  {
    id: 3,
    title: 'See Your Revenue Grow',
    description:
      'Grow with MultiD. Our drones and their accompanying software suite allow you to leverage the skies to achieve more.',
    lightImg: money,
    darkImg: money
  }
];

export const services: Feature[] = [
  {
    id: 1,
    title: 'Lightning Speed',
    description: 'fly fast with MultiD!',
    lightImg: icon1,
    darkImg: darkIcon1
  },
  {
    id: 2,
    title: 'Best Statistics',
    description: 'Get easy to read logs on your ground station!',
    lightImg: icon2,
    darkImg: darkIcon2
  },
  {
    id: 3,
    title: 'Safety',
    description: 'Security Assured: Ensure data Safety with MultiD drones!',
    lightImg: icon3,
    darkImg: darkIcon3
  },
  {
    id: 4,
    title: 'Modular Features',
    description: 'Customize the drone to your needs!',
    lightImg: icon4,
    darkImg: darkIcon4
  }
];

export const stats: Stat[] = [
  {
    id: 1,
    title: 'Flights',
    value: 3000,
    prefix: '>'
  },
  {
    id: 2,
    title: 'Users',
    value: 13,
    suffix: '+'
  },
  {
    id: 3,
    title: 'Countries',
    value: 2
  },
  {
    id: 4,
    title: 'Distance',
    value: 8342,
    suffix: '+ Km'
  }
];

export const blogs: Blog[] = [
  {
    id: 1,
    image: blog5,
    views: 25,
    like: 4,
    comments: 2,
    category: 'MultiCopter',
    title: 'Multicopters 101: The Versatile Workhorses of the Drone Industry'
  },
  {
    id: 2,
    image: blog6,
    views: 12,
    like: 6,
    comments: 3,
    category: 'Surveillance',
    title: 'Eyes in the Sky: UAVs for Enhanced Security and Surveillance'
  },
  {
    id: 3,
    image: blog7,
    views: 17,
    like: 7,
    comments: 2,
    category: 'Logistics',
    title:
      'Revolutionizing Delivery: How VToLs are Taking Logistics to New Heights'
  }
];

export const team: TeamMember[] = [
  {
    id: 1,
    image: pranav,
    name: 'Pranav S Rao',
    designation: 'Director'
  },
  {
    id: 2,
    image: amartya,
    name: 'Amartya Josephat',
    designation: 'R&D Engineer'
  },
  {
    id: 3,
    image: gana,
    name: 'Ganapathi Naayagam',
    designation: 'R&D Engineer'
  },
  {
    id: 4,
    image: yash,
    name: 'Yash Aryaman',
    designation: 'R&D Engineer'
  }
  // {
  //   id: 5,
  //   image: team73,
  //   name: 'Emma Knowles',
  //   designation: 'Backend Dev'
  // },
  // {
  //   id: 6,
  //   image: team74,
  //   name: 'Megan Holmes',
  //   designation: 'HR'
  // },
  // {
  //   id: 7,
  //   image: team75,
  //   name: 'Cameron Fletcher',
  //   designation: 'UX/UI Designer'
  // },
  // {
  //   id: 8,
  //   image: team20,
  //   name: 'Amber Norris',
  //   designation: 'Consultant'
  // },
  // {
  //   id: 9,
  //   image: team76,
  //   name: 'Scooby',
  //   designation: 'Dog'
  // }
];
