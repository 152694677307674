import { Col, Row } from 'react-bootstrap';
import Button from 'components/base/Button';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import bg1 from 'assets/img/bg/redbg-36.png';
import bg5 from 'assets/img/bg/redrone1-removebg-preview.png';
import React from 'react';
import { useState } from 'react';
import DemoPopUp from './DemoPopUp';

//'assets/img/bg/agridrone_mainbg.png';
//

const HeroHeader = () => {
  const [demo, setDemo] = useState(false);
  return (
    <section id="home" className="pb-8 overflow-hidden pt-0">
      <div className="hero-header-container-alternate position-relative pb-1">
        <div className="container-small px-lg-7 px-xxl-3">
          <Row className="align-items-top">
            <Col
              lg={6}
              className="pt-8 pb-6 position-relative z-index-5 text-center text-lg-start"
            >
              <h1 className="fs-3 fs-md-2 fs-xl-1 fw-black mb-4">
                <span className="text-gradient-info me-3">Drones</span> to{' '}
                <br />
                elevate your operations
              </h1>
              <p className="mb-5 pe-xl-10">
                Elegant, Easy to fly drone solutions for your complex needs so
                you can focus on what matters. Sign up or check the demo below.
              </p>
              <Button
                as={Link}
                to="#!"
                variant="primary"
                size="lg"
                className="rounded-pill me-3"
              >
                Sign up
              </Button>
              <Button
                variant="link"
                onClick={() => {
                  setDemo(!demo);
                  // console.log(demo);
                }}
                endIcon={
                  <FontAwesomeIcon icon={faAngleRight} className="ms-2 fs-9" />
                }
                className="me-2 fs-8 p-0"
              >
                Check Demo
              </Button>
            </Col>
            <Col lg="auto" className="d-none d-lg-block">
              <div className="hero-image-container position-absolute h-100 end-0 d-flex align-items-center">
                {!demo ? (
                  <div className="position-relative">
                    <div className="position-absolute end-0 " />
                    {/* //hero-image-container-overlay */}
                    <img
                      src={bg1}
                      alt=""
                      className="position-absolute end-0 hero-image-container-bg"
                    />
                    <img
                      src={bg5}
                      alt=""
                      className="w-100 d-dark-none rounded-2"
                    />
                    <img
                      src={bg5}
                      alt=""
                      className="w-100 d-light-none rounded-2"
                    />
                  </div>
                ) : (
                  <DemoPopUp size="560" />
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div className="container-small px-md-8 mb-8 d-lg-none">
          {!demo ? (
            <div className="position-relative">
              <img
                src={bg1}
                alt=""
                className="position-absolute hero-image-container-bg"
              />
              <img
                src={bg5}
                alt=""
                className="img-fluid ms-auto d-dark-none rounded-2"
              />
              <img
                src={bg5}
                alt=""
                className="img-fluid ms-auto d-light-none rounded-2"
              />
            </div>
          ) : (
            <DemoPopUp size="100%" />
          )}
        </div>
      </div>
    </section>
  );
};

export default HeroHeader;
