import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import Error404 from 'pages/error/Error404';
import App from 'App';
// import ComingSoon from 'pages/ComingSoon';
// import Showcase from 'pages/Showcase';
// import Members from 'pages/Members';
// import EcommerceLayout from 'layouts/EcommerceLayout';
// import AuthCardLayout from 'layouts/AuthCardLayout';
// import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
// import DocPagesLayout from 'layouts/DocPagesLayout';

import Alternate from 'pages/pages/landing/Alternate';
// import Default from 'pages/pages/landing/Default';

// import EcommerceLayout from 'layouts/EcommerceLayout';
import Homepage from 'pages/apps/e-commerce/customer/Homepage';
import ComingSoon from 'pages/ComingSoon';
import ProductDetails from 'pages/apps/e-commerce/customer/ProductDetails';
import Cart from 'pages/apps/e-commerce/customer/Cart';
import Checkout from 'pages/apps/e-commerce/customer/checkout/Checkout';
import ProductsFilter from 'pages/apps/e-commerce/customer/ProductsFilter';
import ShippingInfo from 'pages/apps/e-commerce/customer/checkout/ShippingInfo';
import Profile from 'pages/apps/e-commerce/customer/Profile';
import Wishlist from 'pages/apps/e-commerce/customer/Wishlist';
import FavoriteStores from 'pages/apps/e-commerce/customer/FavoriteStores';
import Invoice from 'pages/apps/e-commerce/customer/Invoice';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <Alternate />
          },
          {
            path: 'homepage',
            element: <Homepage />
          },
          {
            path: 'signup',
            element: <ComingSoon />
          },
          {
            path: 'product/:productId',
            element: <ProductDetails />
          },
          {
            path: 'cart',
            element: <Cart />
          },
          {
            path: 'checkout',
            element: <Checkout />
          },
          {
            path: 'shipping-info',
            element: <ShippingInfo />
          },
          {
            path: 'profile',
            element: <Profile />
          },
          {
            path: 'products-filter',
            element: <ProductsFilter />
          },
          {
            path: 'wishlist',
            element: <Wishlist />
          },
          {
            path: 'favorite-stores',
            element: <FavoriteStores />
          },
          {
            path: 'invoice',
            element: <Invoice />
          }
        ]
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
