import { IconProp } from '@fortawesome/fontawesome-svg-core';
// import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import {
  Icon,
  UilCamera,
  UilTrees,
  UilCube,
  UilEye
} from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'Surveillance Drones',
    horizontalNavLabel: 'Surveillance Drones',
    active: true,
    icon: UilCamera,
    labelDisabled: true,
    pages: [
      {
        name: 'home',
        icon: 'pie-chart',
        active: true,
        flat: true,
        pages: [
          {
            name: 'Nano Surveillance Drones',
            path: '/product/11',
            pathName: 'social-feed',
            topNavIcon: 'UilMicroscope',
            active: true
          },
          {
            name: 'Large Reconnaissance Drones',
            path: '/product/12',
            pathName: 'project-management-dashbaord',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Multi-Spectral Camera Drones',
            path: '/product/13',
            pathName: 'social-feed',
            topNavIcon: 'share-2',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'Logistics Drones',
    icon: UilCube,
    pages: [
      {
        name: 'e-commerce',
        active: true,
        icon: 'shopping-cart',
        flat: true,
        pages: [
          {
            name: 'HCL-50 series',
            path: '/product/21',
            pathName: 'e-commerce-add-product',
            topNavIcon: 'share-2',
            active: true
          },
          {
            name: 'Long Range VTOL',
            path: '/product/22',
            pathName: 'e-commerce-add-product',
            topNavIcon: 'share-2',
            active: true
          },
          {
            name: 'Urban Delivery Drones',
            path: '/product/23',
            pathName: 'e-commerce-add-product',
            topNavIcon: 'share-2',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'Agricultural Drones',
    icon: UilTrees,
    active: true,
    pages: [
      {
        name: '10l Multi-Copter',
        icon: 'life-buoy',
        path: '/product/31',
        pathName: 'getting-started',
        active: true
      },

      {
        name: '16l Multi-Copter',
        icon: 'figma',
        path: '/product/32',
        pathName: 'doc-design-file',
        active: true
      },
      {
        name: '22l Multi-Copter',
        icon: 'git-merge',
        path: '/product/33',
        pathName: 'changelog',
        active: true
      },
      {
        name: '30l Multi-Copter',
        icon: 'fast-forward',
        path: '/product/34',
        pathName: 'migrations',
        active: true
      },
      {
        name: '50l Multi-Copter',
        icon: 'monitor',
        path: '/product/35',
        pathName: 'showcase',
        active: true
      }
    ]
  },
  {
    label: 'Inspection Drones',
    icon: UilEye,
    pages: [
      {
        name: 'Oil Pipeline Inspection Drone',
        icon: 'compass',
        path: '/product/41',
        pathName: 'starter-page',
        active: true
      },
      {
        name: 'Power Line Inspection Drone',
        icon: 'compass',
        path: '/product/42',
        pathName: 'starter-page',
        active: true
      },
      {
        name: 'Marine Infrastructure Inspection Drone',
        icon: 'compass',
        path: '/product/43',
        pathName: 'starter-page',
        active: true
      },
      {
        name: 'Railway Track Inspection Drone',
        icon: 'compass',
        path: '/product/44',
        pathName: 'starter-page',
        active: true
      },
      {
        name: 'Highway Inspection Drone',
        icon: 'compass',
        path: '/product/45',
        pathName: 'starter-page',
        active: true
      },
      {
        name: 'Mining / Industrial Site Inspection Drone',
        icon: 'compass',
        path: '/product/46',
        pathName: 'starter-page',
        active: true
      },
      {
        name: 'Real Estate Survey Drones',
        icon: 'life-buoy',
        path: '/product/47',
        pathName: 'getting-started',
        active: true
      },
      {
        name: 'Agriculture Land Survey Drones',
        icon: 'git-merge',
        path: '/product/48',
        pathName: 'changelog',
        active: true
      },
      {
        name: 'Water Body Survey Drones',
        icon: 'fast-forward',
        path: '/product/49',
        pathName: 'migrations',
        active: true
      },
      {
        name: 'Construction Site Survey Drones',
        icon: 'monitor',
        path: '/product/410',
        pathName: 'showcase',
        active: true
      }
    ]
  }
];
